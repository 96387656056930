import SywCheckoutAnalytics from "./SywCheckoutAnalytics";
import { GTMAnalytics } from "./sywGTMAnalytics/Syw_GTM_Analytics";

function AnalyticsWrapper({ triggerType, event, data , cartId , citiFlag}) {

	// switch (analyticsTool) {
	// 	case "gtm":
    //         console.log("br-1");
	// 		GTMAnalytics(triggerType, event, data , cartId , citiFlag);
	// 		return null;
	// 	case "tealium":
	// 		return <SywCheckoutAnalytics response={data} cart={data} tealiumEvent={event} cartId={cartId} citiFlag={citiFlag}/>;
	// 	default:
	// 		return null;
	// }
    if(process.env.REACT_APP_GTM_ANALYTICS === "true"){
		GTMAnalytics(triggerType, event, data , cartId , citiFlag);
    }
    if(process.env.REACT_APP_TEALIUM_ANALYTICS === "true"){
        return <SywCheckoutAnalytics response={data} cart={data} tealiumEvent={event} cartId={cartId} citiFlag={citiFlag}/>;
    }
    return null;
}
export default AnalyticsWrapper