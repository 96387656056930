import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";

class SywCheckoutItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">Loading...</h5>
      </section>
      );
    } else {
      return (
        <div>
          <div>
            <h4 class="mb-2">
              <strong>Items</strong>
            </h4>
          </div>

          {this.props.pageData.items.map(item => {
            return (
              <div className="data-card">
                <div class="item-card ">
                  <img src={item.imageURL} alt="" />
                  <div class="description">
                    <h5 class="mt-0">
                      <b>{item.itemName}</b>
                    </h5>
                    <p>{item.itemDescription} </p>
                  </div>
                </div>
                <div
                  class="item "
                  style={{ paddingLeft: "6.75rem", paddingRight: "0.85rem" }}
                >
                  <div class="item-image ">
                    <p class="">QTY: {" " + item.quantity}</p>
                  </div>
                  <div class="item-points">
                    <h5 class=" ">
                      <b>Price: {item.currency + item.salePrice}</b>
                    </h5>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}
//export default SywCheckoutItem;

export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutItem);
