import React from "react";
import postscribe from "postscribe";
import cookies from "react-cookies";
import * as rdd from 'react-device-detect';

class SywCheckoutAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          checkout_utag_data : {}, 
          global_utag_data : {}, 
          purchase_utag_data : {},
          linkedClick_utag_data : {}
        };
      }
      componentDidMount() {
        var cart = this.props.cart;
        var event = this.props.tealiumEvent;
        var response = this.props.response;
        var utag_count = window.utag_data_count
        var isError = (this.props.isError==='true')
        //var merchantName = cookies.load("merchant");
        var merchantName=cookies.load("pywmerchant")!==undefined?cookies.load("pywmerchant"):"";
        if (!rdd.isBrowser){
          merchantName = merchantName+"_app";
        }
        if(event==='checkout' && cart!==undefined && (utag_count!==1 || isError)){
          this.prepareUtagCheckout(cart)
          var utag__data_script = '<script type="text/javascript"> var utag_data ='+JSON.stringify(this.state.checkout_utag_data)+'; </script>';
          postscribe("#utag-container", utag__data_script);
          
          var utag_view_script = '<script type="text/javascript"> utag.view(utag_data); </script>';
          postscribe("#utag-container", utag_view_script);          
          var utag_count_script = '<script type="text/javascript"> var utag_data_count =1; </script>';
          postscribe("#utag-container", utag_count_script);
        }else if(event==='purchase' && response!==undefined ){
          var cartId = this.props.cartId
          this.prepareUtagPurchase(response.orderConfirmationData,cartId)
          var utag__data_script = '<script type="text/javascript"> var utag_data =('+JSON.stringify(this.state.purchase_utag_data)+'); </script>';
          postscribe("#utag-container", utag__data_script);
          var utag_view_script = '<script type="text/javascript"> utag.view(utag_data); </script>';
          postscribe("#utag-container", utag_view_script);
        }else if(event==='Linkclick' && response!==undefined ){
          if(this.props.citiFlag!==undefined && this.props.citiFlag==='0'){
            response = Object.assign(response, {'pageType':'giftcards'});
          }else if(this.props.citiFlag!==undefined && this.props.citiFlag==='2'){
            var pywextvar = ('pywext_'+merchantName).toLowerCase();
            response = Object.assign(response, {'pageType':pywextvar});
          }
          this.prepareUtagLinkClick(response)
          var utag__data_script = '<script type="text/javascript"> var utag_data ='+JSON.stringify(this.state.linkedClick_utag_data)+'; </script>';
          postscribe("#utag-container", utag__data_script);     
          var utag_view_script = '<script type="text/javascript"> utag.view(utag_data); </script>';
          postscribe("#utag-container", utag_view_script);
        }
    }

    is5321Transacton(data){
      var is5321TransactonResp = "N"      
    if(data!==undefined && data!==null && data.tenders!== undefined && data.tenders.length>=1){
      data.tenders.map((tender)=>{
        if("CC_DC" === tender.tenderType){
          if(parseFloat(tender.amountPayable) !== 0  &&  tender.creditCartTypeCode !== undefined && tender.creditCartTypeCode === "I"){
            is5321TransactonResp = "Y"
          }
        }
      })
    }
    return is5321TransactonResp;
    }
    getPaymentMethod(data){
      var points = false;
      var cash = false;
      if(data!==undefined && data!==null && data.tenders!== undefined && data.tenders.length>=1){
        data.tenders.map((tender)=>{
            if(("SYW_POINTS" === tender.tenderType) && tender.redeemPoints !== undefined && parseFloat(tender.redeemPoints) > 0){
              points = true;
            }else if(("CC_DC" === tender.tenderType) && tender.amountPayable !== undefined  && parseFloat(tender.amountPayable) > 0){
              cash =true;
            }
        })
      }
    
    var paymentMethod = "";
      if(cash && points){
        paymentMethod = "Mix Tender";
      }else if (cash && !points){
        paymentMethod = "cash";
      }else if(points && !cash){
        paymentMethod = "points";
      }
      return paymentMethod;
    
    }

    prepareUtagLinkClick(response){
      this.state.linkedClick_utag_data={
        "tealium_event":'Linkclick',
      }
      this.prepareUtagGlobalVar(response)
      this.state.linkedClick_utag_data = Object.assign(this.state.linkedClick_utag_data, this.state.global_utag_data);

      var linkedClick_data = {
        "eventCategory": 'Engagement',
        "eventAction": 'Button Click',
        "eventLabel": 'Gift Card Citi Apply and Use',
        "eventName": 'Gift Card',
        "event_name": 'Gift Card',
        "eventValue": 'Apply and Use'
      }

      this.state.linkedClick_utag_data = Object.assign(this.state.linkedClick_utag_data, linkedClick_data);
    }

    prepareUtagPurchase(response, cartId) {
      this.state.purchase_utag_data = {
        'tealium_event': 'purchase'
      }
      if(cartId!==undefined){
        if(response === undefined){
          response = {}
        }
        response = Object.assign(response, {
          'cartId': cartId
        })
      }
      this.prepareUtagGlobalVar(response)
      this.state.purchase_utag_data = Object.assign(this.state.purchase_utag_data, this.state.global_utag_data)
      var purcahse_event_details = {
        "eventCategory": 'ecommerce',
        "eventAction": 'placeOrder click',
        "eventLabel": 'order confirmation',
        "eventName": 'pywcheckout',
        "event_name": 'pywcheckout'
      }
      this.state.purchase_utag_data = Object.assign(this.state.purchase_utag_data, purcahse_event_details)
      if(response !== undefined){
        var orderId = response.omsId;
        var order_total = response.summary!==undefined?response.summary.priceSubTotal:"";
        var order_currency = "USD";
        var order_zip = "";
        if(response.ffm!==undefined && response.ffm!==""){
          response.ffm.map( (ffm_address) => {
            if(ffm_address.type!==undefined && ffm_address.type==='SHIP'){
              var shipAddress = ffm_address.address;
              if(shipAddress!==undefined){
                order_zip= shipAddress.zipCode;
              }
            } 
          }
          );
        }

        var order_points = response.summary!==undefined?response.summary.redeemPoints:"";
        var payment_method = this.getPaymentMethod(response);
        var items = response.summary!==undefined?response.summary.items:"";
        var itemIds = [];
        var itemNames = [];
        var itemPrices = [];
        var itemQty = [];
        var position = [];
        var is5321Transacton = this.is5321Transacton(response);
        for (const key in items) {
          position.push(Number(key) + 1)
          for (let item in items[key]) {
            if (item === "itemId") {
              itemIds.push(items[key][item])
            }
            if (item === "itemName") {
              itemNames.push(items[key][item])
            }
            if (item === "salePrice") {
              itemPrices.push(items[key][item])
            }
            if (item === "quantity") {
              itemQty.push(items[key][item])
            }
          }
        }       
        var purchase_data = {
          "transaction_id": orderId,
          "order_id": orderId,
          "value": order_total,
          "currency": order_currency,
          "order_zip": order_zip,
          "order_points": order_points,
          "payment_method": payment_method,
          "item_id": itemIds,
          "item_name": itemNames,
          "item_brand": [],
          "item_variant": [],
          "item_category": [],
          "price": itemPrices,
          "quantity": itemQty,
          "5321Transaction" : is5321Transacton
        }
      }
    
      this.state.purchase_utag_data = Object.assign(this.state.purchase_utag_data, purchase_data)
    
    
    }

    prepareUtagCheckout(cart){

      // var utag_cartId = cart.cartId;
      var itemIds = [];
      var itemNames = [];
      var itemPrices=[];
      var itemQty = [];
      var position = [];
      if(cart!==undefined && cart.items !== undefined){
        var items = cart.items;
        for (const key in items) {
          position.push(Number(key)+1)
          for (let item in items[key]) {
            if(item === "itemId"){
              itemIds.push(items[key][item])
            }
            if(item === "itemName"){
              itemNames.push(items[key][item])
            }
            if(item === "salePrice"){
              itemPrices.push(items[key][item])
            }
            if(item === "quantity"){
              itemQty.push(items[key][item])
            }
          }
      }
      }
    this.state.checkout_utag_data = {
      'tealium_event'    : 'checkout'
    }
    var errors = "";
    var errorEventAction = "";
    var errorEventLabel = "";
    if(cart!== undefined && cart!==null){
      if(cart.analyticsAPIErrorCode!== undefined && cart.analyticsAPIErrorCode!== null && cart.analyticsAPIErrorCode!== '' ){
        if(cart.analyticsAPIErrorCode==="1"){
          errors = cart.spcPageError;
        }else if(cart.analyticsAPIErrorCode==="2"){
          if(cart.applyPointsError instanceof Array){
            cart.applyPointsError.map((error) => errors = error.message);
          }
          errorEventAction="applynow points click";
            if("Insufficient Points Balance"===errors){
              errorEventLabel ="insufficient funds" 
            }
        }else if(cart.analyticsAPIErrorCode==="3"){
          if(cart.applyPointsError instanceof Array){
            cart.applyPointsError.map((error) => errors = error.message);
          }
          errorEventAction="place order click";
          errorEventLabel = "invalid card"
        }else if(cart.analyticsAPIErrorCode==="4"){
          if(cart.applyPointsError instanceof Array){
            cart.applyPointsError.map((error) => errors = error.message);
          }
          errorEventAction="place order click";
          if("CreditCard Authorization Failed"===errors){
            errorEventLabel = "authentication failure"
          }else{
            errorEventLabel = "unsuccessful place order"
          }
        }
      }
    }
    this.prepareUtagGlobalVar(cart)
    this.state.checkout_utag_data = Object.assign(this.state.checkout_utag_data, this.state.global_utag_data)
    if("" !== errors){
      var checkout_events_details = {
        "eventCategory": "errors",
        "eventAction": errorEventAction,
        "eventLabel": errorEventLabel,
        "eventName": 'pyw checkout Error',
        "event_name": 'pyw checkout Error'
    }
    }else{
      var checkout_events_details = {
        "eventCategory": 'ecommerce',
        "eventAction": 'checkout click',
        "eventLabel": window.location.origin+window.location.pathname,
        "eventName": 'pywcheckout',
        "event_name": 'pywcheckout'
      }
    }
    this.state.checkout_utag_data = Object.assign(this.state.checkout_utag_data, checkout_events_details)
    var is5321CardHolder = this.is5321CardHolder(cart);
    var checkout_data = {
          'errorMessage'  :errors,
          'item_id'       : itemIds,
          'item_name'     : itemNames,
          'item_brand'    : [],
          'item_variant'  : [],
          'item_category' : [],
          'price'         : itemPrices, 
          'quantity'      : itemQty, 
          'product_unit_price' : itemPrices,    
          'product_position' : position,
          '5321CardHolder': is5321CardHolder,
          'checkout_step' : '1' 
    }

    this.state.checkout_utag_data = Object.assign(this.state.checkout_utag_data, checkout_data)
      
    }

    is5321CardHolder(data){
      var is5321CardHolder = "N";
      if(data!==undefined && data.tenders instanceof Array){
        data.tenders.map((tender)=>{
          if( "CC_DC" === tender.tenderType){
            if(tender.savedCards !== undefined && tender.savedCards instanceof Array && tender.savedCards.length>0){
              tender.savedCards.some(card => card.brand === "SYW" ? is5321CardHolder = "Y" : "N") 
            }
          }
        })
      }
      return is5321CardHolder;
    }

    prepareUtagGlobalVar(response){

      var authenticationType = "Email";
      var utag_cartId = "";
      if(response!==undefined && response.cartId !== undefined){
        utag_cartId = response.cartId;
      }
      var eventAction = "Checkout Click";
      var eventCategory = "PYW Checkout";
      var eventLabel = "";
      var eventName = "";
      var eventValue = "";
      var hashedEmail = "";
      if(response!==undefined && response.emailHash !== undefined){
        hashedEmail = response.emailHash;
      }
      const queryString = require("query-string");
      const url_search_var = queryString.parse(window.location.search);
      var intcmp = "";
      if(url_search_var.intcmp!==undefined && url_search_var.intcmp!=null){
        intcmp = url_search_var.intcmp
      }
      var membershipType = "Registered";
      var url_path_name = window.location.pathname;
      var page = window.location.origin+url_path_name;
      var pageName = "order";
      if(url_path_name!==undefined && url_path_name==='/OrderPlaced'){
        pageName = "order_confirmation"
      }else if(url_path_name!==undefined && url_path_name==='/citiredirection'){
        pageName = "citi_redirection"
      }
      const perfData = window.performance.timing;
      const pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
      var pageLoadTimeSec = (pageLoadTime / 1000) % 60;
      var pagePerformance = pageLoadTimeSec;
      var pageType = "giftcards";
      if(response!==undefined && response!==null && response!==""){
        if(response.pageType!==undefined && response.pageType!==pageType && response.pageType!==null && response.pageType!==""){
          pageType=response.pageType;
        }
      }
      var screen_title = "SYW Checkout";
      var siteSection = "pyw giftcard";
      var sid = "";
      var s_pers_cookies = cookies.load("s_pers");
      if(s_pers_cookies!==undefined){
        var s_pers = s_pers_cookies.split(";");
        s_pers.map((s_pers_nodes) => {
          s_pers_nodes=s_pers_nodes.trim();
          var s_pers_nodes_slit = s_pers_nodes.split('=');
          if(s_pers_nodes_slit[0].replace(/ /g,'')==='campaign'){
            sid= s_pers_nodes_slit[1];
          }
        });
      }
      if(sid ==="" && url_search_var!==undefined && url_search_var!==null && url_search_var.SID!==undefined && url_search_var.SID!=null){
        sid = url_search_var.SID
      }
      var user_agent = navigator.userAgent.toLowerCase();
      var siteId = "Unspecified";
      if(user_agent.indexOf("android")!==-1){
        siteId = "Android"
      }else if(user_agent.indexOf("iphone")!==-1){
        siteId = "iPhone"
      }else if(user_agent.indexOf("mobile")!==-1){
        siteId = "Mobile"
      }else if(user_agent.indexOf("windows")!==-1 || user_agent.indexOf("mac")!==-1 || user_agent.indexOf("linux")!==-1){
        siteId = "Web"
      }
      var siteLanguage = navigator.language;
      var ssoUserId = "";
      var sywUserId = "";
      if(response!==undefined && response.sywrUserId !== undefined){
        sywUserId = response.sywrUserId;
      }
      var tid = "";
      if(response!==undefined && response.memberNumber !== undefined){
        tid = response.memberNumber;
      }
      var userType = "Registered";

      this.state.global_utag_data={
          'authenticationType':authenticationType,
          'checkoutCartId' : utag_cartId,
          'errorMessage':'',
          'eventAction':eventAction,
          "eventCategory": eventCategory,
          "eventLabel":eventLabel,
          "eventName":eventName,
          "eventValue":eventValue,
          "hashedEmail":hashedEmail,
          "intcmp":intcmp,
          "membershipType":membershipType,
          "page":page,
          "pageName":pageName,
          "pagePerformance": pagePerformance,
          "pageType":pageType,
          "screen_title":screen_title,
          "sid":sid,
          'siteId': siteId,
          'siteLanguage' : siteLanguage,
          "siteSection":siteSection,
          'ssoUserId' : ssoUserId,
          'sywUserId':sywUserId,
          'tid' : tid,
          'userType' : userType,
          'visitorId' : ''
      }

    }


  render() {
    return <div id="utag-container" />;
  }
}
  
export default SywCheckoutAnalytics;