export const CODE_100 = '100'
export const CODE_200 = '200'
export const CODE_300 = '300'
export const CODE_400 = '400'

export const TRUE = 'true'
export const FALSE = 'false'

export const LOADING = 'Loading...'
export const CHANGE = 'CHANGE'

export const FFMEMAIL = 'EMAIL'
export const FFMSHIP = 'SHIP'

export const LWEB  = 'lweb'
export const NWEB = 'nweb'

export const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform:"CHECKOUT",
      
      };

export const ERROR_CODES = {
      SUCCESS: "00",
      ERROR: "99"
}

export const STATUS_CODES = {
      SUCCESS: "success",
      ERROR: "Invalid request"
}