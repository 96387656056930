import * as rdd from 'react-device-detect';
import cookies from 'react-cookies';

export function GTMAnalyticsGenerateData(data, event, cartId, citiFlag) {
  var cart = data;
  var event = event;
  var response = data;
//   var isError = this.props.isError === "true";
  var merchantName =
    cookies.load("pywmerchant") !== undefined
      ? cookies.load("pywmerchant")
      : "";
  if (!rdd.isBrowser) {
    merchantName = merchantName + "_app";
  }
  let GLOBAL_DATA = prepareGlobalVar(response);
  let GTM_DATA = {
    ...GLOBAL_DATA,
  };
  switch (event) {
    case "checkout":
      GTM_DATA = {
        ...GTM_DATA,
        ...prepareCheckout(cart),
        event: "page_view_syw",
        eventAction: "syw_checkout_page",
        eventCategory:"syw_checkout",
        eventValue: "checkout",
        authenticationStatus: "Registered", //Registered/Anonymous
        usertype: "Repeat",
        pagetype:"giftcards"
      };
      break;
    case "purchase":
      var cartId = cartId;

      GTM_DATA = {
        ...GTM_DATA,
        ...preparePurchase(response, cartId),
        event: "purchase",
        eventAction: "syw_place_order_page",
        eventCategory: "syw_checkout",
        eventLabel : "order_confirmation_page",
        eventValue: "thankyou_for_order",
        authenticationStatus: "Registered", //Registered/Anonymous
        usertype: "Repeat",
        pagetype:"giftcards",
      };
      break;
    case "Linkclick":
        let pageTypeLinkClick = "giftcards"
      if (citiFlag !== undefined && citiFlag === "0") {
          pageTypeLinkClick = "giftcards"
      } else if (citiFlag !== undefined && citiFlag === "2") {
        var pywextvar = ("pywext_" + data?.merchantName).toLowerCase();
        pageTypeLinkClick = pywextvar
      }
      GTM_DATA = {
        ...GTM_DATA,
        ...prepareLinkClick(response),
        event: "Click",
        eventAction: "Apply Now",
        eventCategory: "Citi 5321card",
        eventLabel: citiFlag === "2" ? "PYW apply and buy" : "checkout apply and use now",
        eventValue: process.env.REACT_APP_CITI_URL,
        usertype: "Repeat",
        pagetype: pageTypeLinkClick
      };
      break;
    default:
      break;
  }
console.log(GTM_DATA);
  return GTM_DATA
}

function prepareGlobalVar(response) {
  var authenticationType = "Email";
  var utag_cartId = "";
  if (response !== undefined && response.cartId !== undefined) {
    utag_cartId = response.cartId;
  }
  var eventAction = "Checkout Click";
  var eventCategory = "PYW Checkout";
  var eventLabel = "";
//   var eventName = "";
  var eventValue = "";
  var hashedEmail = "";
  if (response !== undefined && response.emailHash !== undefined) {
    hashedEmail = response.emailHash;
  }
  const queryString = require("query-string");
  const url_search_var = queryString.parse(window.location.search);
  var intcmp = "";
  if (url_search_var.intcmp !== undefined && url_search_var.intcmp != null) {
    intcmp = url_search_var.intcmp;
  }
//   var membershipType = "Registered";
  var url_path_name = window.location.pathname;
  var page = window.location.origin + url_path_name;
  var pageName = "order";
  if (url_path_name !== undefined && url_path_name === "/OrderPlaced") {
    pageName = "order_confirmation";
  } else if (
    url_path_name !== undefined &&
    url_path_name === "/citiredirection"
  ) {
    pageName = "order";
  }
  const perfData = window.performance.timing;
  const pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
  var pageLoadTimeSec = (pageLoadTime / 1000) % 60;
  var pagePerformance = pageLoadTimeSec;
//   if (response !== undefined && response !== null && response !== "") {
//     if (
//       response.pageType !== undefined &&
//       response.pageType !== pageType &&
//       response.pageType !== null &&
//       response.pageType !== ""
//     ) {
//       pageType = response.pageType;
//     }
//   }
  var screen_title = "SYW Checkout";
//   var siteSection = "pyw giftcard";
  var sid = "";
  var s_pers_cookies = cookies.load("s_pers");
  if (s_pers_cookies !== undefined) {
    var s_pers = s_pers_cookies.split(";");
    s_pers.map((s_pers_nodes) => {
      s_pers_nodes = s_pers_nodes.trim();
      var s_pers_nodes_slit = s_pers_nodes.split("=");
      if (s_pers_nodes_slit[0].replace(/ /g, "") === "campaign") {
        sid = s_pers_nodes_slit[1];
      }
    });
  }
  if (
    sid === "" &&
    url_search_var !== undefined &&
    url_search_var !== null &&
    url_search_var.SID !== undefined &&
    url_search_var.SID != null
  ) {
    sid = url_search_var.SID;
  }
  var user_agent = navigator.userAgent.toLowerCase();
  var siteId = "Unspecified";
  if (user_agent.indexOf("android") !== -1) {
    siteId = "Android";
  } else if (user_agent.indexOf("iphone") !== -1) {
    siteId = "iPhone";
  } else if (user_agent.indexOf("mobile") !== -1) {
    siteId = "Mobile";
  } else if (
    user_agent.indexOf("windows") !== -1 ||
    user_agent.indexOf("mac") !== -1 ||
    user_agent.indexOf("linux") !== -1
  ) {
    siteId = "Web";
  }
  var siteLanguage = navigator.language;
//   var ssoUserId = "";
  var sywUserId = "";
  if (response !== undefined && response.sywrUserId !== undefined) {
    sywUserId = response.sywrUserId;
  }
//   var tid = "";
//   if (response !== undefined && response.memberNumber !== undefined) {
//     tid = response.memberNumber;
//   }
  var userType = "Registered";

  return {
    authenticationType: authenticationType,
    checkoutCartId: utag_cartId,
    errorMessage: "",
    eventAction: eventAction,
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    // eventName: eventName,
    eventValue: eventValue,
    hashedEmail: hashedEmail,
    intcmp: intcmp,
    // membershipType: membershipType,
    pageUrl: page,
    pagename: pageName,
    pagePerformance: pagePerformance,
    screen_title: screen_title,
    sid: sid,
    siteid: siteId,
    siteLanguage: siteLanguage,
    // siteSection: siteSection,
    // ssoUserId: ssoUserId,
    userId: response.memberNumber,
    // tid: tid,
    usertype: userType,
    // visitorId: "",
    transaction_id:response?.omsId
  };
}
function prepareCheckout(cart) {
  // var utag_cartId = cart.cartId;
  var itemIds = [];
  var itemNames = [];
  var itemPrices = [];
  var itemQty = [];
  var position = [];

  if (cart !== undefined && cart.items !== undefined) {
    var items = cart.items;
    for (const key in items) {
      position.push(Number(key) + 1);
      for (let item in items[key]) {
        if (item === "itemId") {
          itemIds.push(items[key][item]);
        }
        if (item === "itemName") {
          itemNames.push(items[key][item]);
        }
        if (item === "salePrice") {
          itemPrices.push(items[key][item]);
        }
        if (item === "quantity") {
          itemQty.push(items[key][item]);
        }
      }
    }
  }
  var checkoutData = {};
  var errors = "";
  var errorEventAction = "";
  var errorEventLabel = "";
  if (cart !== undefined && cart !== null) {
    if (
      cart.analyticsAPIErrorCode !== undefined &&
      cart.analyticsAPIErrorCode !== null &&
      cart.analyticsAPIErrorCode !== ""
    ) {
      if (cart.analyticsAPIErrorCode === "1") {
        errors = cart.spcPageError;
      } else if (cart.analyticsAPIErrorCode === "2") {
        if (cart.applyPointsError instanceof Array) {
          cart.applyPointsError.map((error) => (errors = error.message));
        }
        errorEventAction = "applynow points click";
        if ("Insufficient Points Balance" === errors) {
          errorEventLabel = "insufficient funds";
        }
      } else if (cart.analyticsAPIErrorCode === "3") {
        if (cart.applyPointsError instanceof Array) {
          cart.applyPointsError.map((error) => (errors = error.message));
        }
        errorEventAction = "place order click";
        errorEventLabel = "invalid card";
      } else if (cart.analyticsAPIErrorCode === "4") {
        if (cart.applyPointsError instanceof Array) {
          cart.applyPointsError.map((error) => (errors = error.message));
        }
        errorEventAction = "place order click";
        if ("CreditCard Authorization Failed" === errors) {
          errorEventLabel = "authentication failure";
        } else {
          errorEventLabel = "unsuccessful place order";
        }
      }
    }
  }
  if ("" !== errors) {
    var checkout_events_details = {
      eventCategory: "errors",
      eventAction: errorEventAction,
      eventLabel: errorEventLabel,
    //   eventName: "pyw checkout Error",
    //   event_name: "pyw checkout Error",
    };
  } else {
    var checkout_events_details = {
      eventCategory: "ecommerce",
      eventAction: "checkout click",
      eventLabel: window.location.origin + window.location.pathname,
    //   eventName: "pywcheckout",
    //   event_name: "pywcheckout",
    };
  }
  checkoutData = {
    ...checkoutData,
    // checkout_events_details,
  };
  var checkout_data = {

  };

  return {
    ...checkoutData,
    errorMessage: errors,
    item_id: itemIds,
    item_name: itemNames,
    item_brand: [],
    item_variant: [],
    item_category: [],
    price: itemPrices,
    quantity: itemQty,
    // product_unit_price: itemPrices,
    // product_position: position,
    "cardholder5321": is5321CardHolder(cart),
    checkout_step: "1",
  };
}
function preparePurchase(response, cartId) {
  var purchaseData = {};
  if (cartId !== undefined) {
    if (response === undefined) {
      response = {};
    }
    response = {
      ...response,
      cartId: cartId,
    };
  }
  var purcahse_event_details = {
  
    // eventName: "pywcheckout",
    // event_name: "pywcheckout",
  };
  purchaseData = {
    ...purchaseData,
    purcahse_event_details,
  };
  if (response !== undefined) {
    var orderId = response.omsId;
    var order_total =
      response.summary !== undefined ? response.summary.priceSubTotal : "";
    var order_currency = "USD";
    var order_zip = "";
    if (response.ffm !== undefined && response.ffm !== "") {
      response.ffm.map((ffm_address) => {
        if (ffm_address.type !== undefined && ffm_address.type === "SHIP") {
          var shipAddress = ffm_address.address;
          if (shipAddress !== undefined) {
            order_zip = shipAddress.zipCode;
          }
        }
      });
    }

    var order_points =
      response.summary !== undefined ? response.summary.redeemPoints : "";
    var items = response.summary !== undefined ? response.summary.items : "";
    var itemIds = [];
    var itemNames = [];
    var itemPrices = [];
    var itemQty = [];
    var position = [];
    for (const key in items) {
      position.push(Number(key) + 1);
      for (let item in items[key]) {
        if (item === "itemId") {
          itemIds.push(items[key][item]);
        }
        if (item === "itemName") {
          itemNames.push(items[key][item]);
        }
        if (item === "salePrice") {
          itemPrices.push(items[key][item]);
        }
        if (item === "quantity") {
          itemQty.push(items[key][item]);
        }
      }
    }
  }

  return {
    ...purchaseData,
    // transaction_id: orderId,
    //   order_id: orderId,
      value: order_total,
      currency: order_currency,
      order_zip: order_zip,
      order_points: order_points,
      payment_method: getPaymentMethod(response),
      item_id: itemIds,
      item_name: itemNames,
      item_brand: [],
      item_variant: [],
      item_category: [],
      price: itemPrices,
      quantity: itemQty,
      "5321Transaction": is5321Transacton(response),
  };
}
function prepareLinkClick(response) {
  var linkedClickData = {};

  var linkedClick_data = {
    eventCategory: "Engagement",
    eventAction: "Button Click",
    eventLabel: "Gift Card Citi Apply and Use",
    // eventName: "Gift Card",
    // event_name: "Gift Card",
    eventValue: "Apply and Use",
  };

  return {
    ...linkedClickData,
    linkedClick_data,
  };
}
function is5321Transacton(data){
    var is5321TransactonResp = "No"      
    if(data!==undefined && data!==null && data.tenders!== undefined && data.tenders.length>=1){
      data.tenders.map((tender)=>{
        if("CC_DC" === tender.tenderType){
          if(parseFloat(tender.amountPayable) !== 0  &&  tender.creditCartTypeCode !== undefined && tender.creditCartTypeCode === "I"){
            is5321TransactonResp = "Yes"
          }
        }
      })
    }
    return is5321TransactonResp;
}
function getPaymentMethod(data){
    var points = false;
    var cash = false;
    if(data!==undefined && data!==null && data.tenders!== undefined && data.tenders.length>=1){
      data.tenders.map((tender)=>{
          if(("SYW_POINTS" === tender.tenderType) && tender.redeemPoints !== undefined && parseFloat(tender.redeemPoints) > 0){
            points = true;
          }else if(("CC_DC" === tender.tenderType) && tender.amountPayable !== undefined  && parseFloat(tender.amountPayable) > 0){
            cash =true;
          }
      })
    }
  
  var paymentMethod = "";
    if(cash && points){
      paymentMethod = "mixed";
    }else if (cash && !points){
      paymentMethod = "card";
    }else if(points && !cash){
      paymentMethod = "points";
    }
    return paymentMethod;
  
  }
function is5321CardHolder(data){
    var is5321CardHolder = "No";
    if(data!==undefined && data.tenders instanceof Array){
      data.tenders.map((tender)=>{
        if( "CC_DC" === tender.tenderType){
          if(tender.savedCards !== undefined && tender.savedCards instanceof Array && tender.savedCards.length>0){
            tender.savedCards.some(card => card.brand === "SYW" ? is5321CardHolder = "Yes" : "No") 
          }
        }
      })
    }
    return is5321CardHolder;
  }