export const FETCH_PREPARECHECKOUT_REQUEST = 'FETCH_PREPARECHECKOUT_REQUEST'
export const FETCH_PREPARECHECKOUT_SUCCESS = 'FETCH_PREPARECHECKOUT_SUCCESS'
export const FETCH_PREPARECHECKOUT_FAILURE = 'FETCH_PREPARECHECKOUT_FAILURE'


export const FETCH_APPLYPOINTS_REQUEST = 'FETCH_APPLYPOINTS_REQUEST'
export const FETCH_APPLYPOINTS_SUCCESS = 'FETCH_APPLYPOINTS_SUCCESS'
export const FETCH_APPLYPOINTS_SUCCESS_ERROR = 'FETCH_APPLYPOINTS_SUCCESS_ERROR'
export const FETCH_APPLYPOINTS_FAILURE = 'FETCH_APPLYPOINTS_FAILURE'


export const FETCH_PLACEORDER_REQUEST = 'FETCH_PLACEORDER_REQUEST'
export const FETCH_PLACEORDER_SUCCESS = 'FETCH_PLACEORDER_SUCCESS'
export const FETCH_PLACEORDER_SUCCESS_ERROR = 'FETCH_PLACEORDER_SUCCESS_ERROR'
export const FETCH_PLACEORDER_FAILURE = 'FETCH_PLACEORDER_FAILURE'

export const FETCH_CREDITCARD_REQUEST = 'FETCH_CREDITCARD_REQUEST'
export const FETCH_CREDITCARD_SUCCESS = 'FETCH_CREDITCARD_SUCCESS'
export const FETCH_CREDITCARD_SUCCESS_ERROR = 'FETCH_CREDITCARD_SUCCESS_ERROR'
export const FETCH_CREDITCARD_FAILURE = 'FETCH_CREDITCARD_FAILURE'


export const HIDE_CREDITCARD_SECTION = 'HIDE_CREDITCARD_SECTION'
export const SHOW_CREDITCARD_SECTION = 'SHOW_CREDITCARD_SECTION'

export const CREDITCARD_DETAILS = 'CREDITCARD_DETAILS'
export const CREDITCARD_NUMBER = 'CREDITCARD_NUMBER'
export const CREDITCARD_CVV = 'CREDITCARD_CVV'
export const CREDITCARD_EXPIRY = 'CREDITCARD_EXPIRY'
export const CREDITCARD_NAME = 'CREDITCARD_NAME'
export const CREDITCARD_ZIPCODE = 'CREDITCARD_ZIPCODE'
export const CREDITCARD_DETAILS_REQUIRED_ERROR = 'CREDITCARD_DETAILS_REQUIRED_ERROR'
export const CREDITCARD_DETAILS_CLEAR = 'CREDITCARD_DETAILS_CLEAR'



export const SAVE_CREDITCARD_CVV = 'SAVE_CREDITCARD_CVV'
export const SAVE_CREDITCARD_ID = 'SAVE_CREDITCARD_ID'
export const SAVE_CREDITCARD_EXPIRY = 'SAVE_CREDITCARD_EXPIRY'
export const SAVE_CREDITCARD_DETAILS_REQUIRED_ERROR = 'SAVE_CREDITCARD_DETAILS_REQUIRED_ERROR'
export const SAVE_CREDITCARD_DETAILS_CLEAR = 'SAVE_CREDITCARD_DETAILS_CLEAR'

export const ANALYTICS_PREPARECHECKOUT_ERROR_CODE = '1'
export const ANALYTICS_APPLYPOINTS_ERROR_CODE = '2'
export const ANALYTICS_CREDITCARD_ERROR_CODE = '3'
export const ANALYTICS_PLACEORDER_ERROR_CODE = '4'