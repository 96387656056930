import { Analytics } from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import { GTMAnalyticsGenerateData } from "./Syw_GTM_Analytics_Helper";

const gtmUrl = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_SYW_GTM_ID}&gtm_auth=${process.env.REACT_APP_SYW_GTM_AUTH}&gtm_preview=${process.env.REACT_APP_SYW_GTM_PREVIEW}&tm_cookies_win=x`;
// "https://www.googletagmanager.com/gtm.js?id=GTM-TW2C758&gtm_auth=03h9SIL1buyA-sUu1mEGKw&gtm_preview=env-3&gtm_cookies_win=x";
export const analytics = Analytics({
  app: "awesome-app",
  plugins: [
    googleTagManager({
      containerId: "GTM-TW2C758",
      customScriptSrc: gtmUrl,
    }),
  ],
});
function trackPageView(data, event , cartId , citiFlag) {
  const GTM_DATA = GTMAnalyticsGenerateData(data, event, cartId, citiFlag);
  console.log("br-3");

  analytics.page( {
    ...GTM_DATA,
    // event: "page_view_syw",
  });
 
}

function trackEvent(event, data) {
  const GTM_DATA = GTMAnalyticsGenerateData(data);
}

export function GTMAnalytics(triggerType, event, data , cartId , citiFlag) {
  switch (triggerType) {
    case "page":
        console.log("br-2");
      return trackPageView(data, event , cartId , citiFlag);
    case "event":
      return trackEvent(event, data);
    default:
      return;
  }
}
