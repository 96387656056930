import axios from 'axios'
import cookie from 'react-cookies'
import {
    FETCH_ORDERCONFIRMATION_REQUEST,
    FETCH_ORDERCONFIRMATION_SUCCESS,
    FETCH_ORDERCONFIRMATION_FAILURE,
    FETCH_ORDERCONFIRMATION_SUCCESS_ERROR,
} from './sywCheckoutOrderConfirmationTypes'



//preparecheckout API Call Start
export const fetchOrderConfirmation = (requestOptions) => {
       return (dispatch) => {
        dispatch(fetchOrderConfirmationRequest())

        //axios.get('https://checkout.uat.telluride.intra.transformco.com/telluride/api/checkout/v1/getOrderConfirmation',
          axios.get(process.env.REACT_APP_SYW_CHECKOUT_ORDER_CONFIRMATION_URL,
            requestOptions).then(resp => {
                 if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(fetchOrderConfirmationSuccessError(resp.data))
                } else {
                dispatch(fetchOrderConfirmationSuccess(resp.data))
                }
            })
            .catch(err => {
                dispatch(fetchOrderConfirmationFailure(err.message))
            });
    }
}

export const fetchOrderConfirmationRequest = () => {
    return {
        type: FETCH_ORDERCONFIRMATION_REQUEST
    }
}

export const fetchOrderConfirmationSuccess = apiData => {
    return {
        type: FETCH_ORDERCONFIRMATION_SUCCESS,
        payload: apiData
    }
}
export const fetchOrderConfirmationSuccessError = apiData => {
    return {
        type: FETCH_ORDERCONFIRMATION_SUCCESS_ERROR,
        payload: apiData
    }
}

export const fetchOrderConfirmationFailure = error => {
    return {
        type: FETCH_ORDERCONFIRMATION_FAILURE,
        payload: error
    }
}

//preparecheckout API Call END